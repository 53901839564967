import "./App.css";
import { useState, useEffect } from "react";
import { useCookies } from 'react-cookie';
import { QRCodeSVG } from 'qrcode.react';
// import QrReader from 'react-qr-scanner';
import { QrReader } from 'react-qr-reader';
import { 
  BiQr, 
  BiAlignLeft
} from "react-icons/bi";
import { FcPaid, FcExpired } from "react-icons/fc";
import { BsGear } from "react-icons/bs";
import { HiOutlineMenu } from "react-icons/hi";
import { IconContext } from "react-icons";
import { w3cwebsocket } from "websocket"
import ecashSmall from './small_ecash.png';
import buxIcon from './bux-icon-57x57.png';

const jwt = require('jsonwebtoken');
// const jwa = require('jwa');

const pubKey = '-----BEGIN PUBLIC KEY-----\nMFYwEAYHKoZIzj0CAQYFK4EEAAoDQgAE5SGZtfaBWqx6ngRkrKyq7hjLGmxpxTlo\nrabVnDNaqDxsKSq1exsc6xf8HjBfs5ho/1yjIX8u8bTqAJ+Y/2Ol8g==\n-----END PUBLIC KEY-----\n';
const publicKey = pubKey.replace(/\\n/g, '\n');

function App() {
  const [name, setName] = useState("");
  const [price, setPrice] = useState("");
  const [cookies, setCookie] = useCookies([]);

  const [nameError, /* setNameError */] = useState("");
  const [priceError, setPriceError] = useState("");
  const [requestError, setRequestError] = useState(null);

  const [paymentUrl, setPaymentUrl] = useState(null);

  const [validJwt, setValidJwt] = useState(null);
  const [jwtMeta, setJwtMeta] = useState(null);

  const [hasResults, setHasResults] = useState(false);
  const [paidStatus, setPaidStatus] = useState('open');

  const [showQr, setShowQR] = useState(true);
  const [showSettings, setShowSettings] = useState(false);

  const [createdPrs, setCreatedPrs] = useState([]);

  const handleScan = async (scanData, error) => {
    // console.log(`loaded data data`, scanData);
    if (!!scanData) {
      console.log(`loaded >>>`, scanData);
      // First public key in subject must match public key for token
      const token = scanData.text;
      try {
        const verified = jwt.verify(token, publicKey);
        console.log("jwt verified?", verified ? true : false);
        console.log("verified", verified);
        if (verified) {
          console.log("JWT token:", token);
          const expires = new Date();
          expires.setDate(expires.getDate() + 90); // store for 90 days
          setCookie('jwt', token, {path: '/', expires});
          setValidJwt(token);
          setJwtMeta(verified);
        }
      } catch (err) {
        console.error(err);
      }
    }
  };

  const handleError = (err) => {
    console.error(err);
  };

  const handleGoBack = () => {
    setName("");
    setPrice("");
    setRequestError(null);
    setPaidStatus("open");
    setHasResults(false);
  }

  const handlePriceChange = (e) => {
    const amount = e.target.value;

    if (!amount || amount.match(/^\d{0,}(\.\d{0,2})?$/)) {
      setPrice(e.target.value);
      setPriceError("");
    } else {
      setPriceError("Must be valid USD amount");
    }
  };

  // Populate fields if url arguments passed
  useEffect(() => {
    if(cookies["jwt"] !== undefined) {
      setValidJwt(cookies["jwt"]);
      const verified = jwt.verify(cookies["jwt"], publicKey);
      setJwtMeta(verified);
    }
    // console.log('localStorage.getItem("prs")', localStorage.getItem("prs"));
    if(localStorage.getItem("prs")) {
      // console.log('localStorage.getItem("prs")?.length', localStorage.getItem("prs")?.length);
      const prsString = Buffer.from(localStorage.getItem("prs"), 'base64').toString('utf8');
      setCreatedPrs(JSON.parse(prsString));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // Update pr fetch interval
    const prInterval = setInterval(() => {
      updatePrData();
    }, 30 * 1000);
    return () => {
      clearInterval(prInterval);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createdPrs]);

  // Populate fields if url arguments passed
  useEffect(() => {
    // console.log("in effect for ws");
    if(!paymentUrl)
      return;

    const client = new w3cwebsocket (paymentUrl.replace('https', 'wss'), "echo-protocol");

    client.onerror = function() {
        console.log("Connection Error");
    }

    client.onopen = function() {
      const splitUrl = paymentUrl.split('/');
      client.send(splitUrl[splitUrl.length -1]);
    };

    // const self = this
    client.onmessage = async function(e) {
      if (typeof e.data === "string") {
          const invoice = JSON.parse(e.data)
          if (invoice.status === 'paid') {
            setPaidStatus('paid');
            createdPrs[createdPrs.length - 1].status = 'paid';
            createdPrs[createdPrs.length - 1].txid = invoice.txHash;
            setCreatedPrs(createdPrs);
            localStorage.setItem("prs", Buffer.from(JSON.stringify(createdPrs), 'utf8').toString('base64'));
            // setCookie('prs', Buffer.from(JSON.stringify(createdPrs), 'utf8').toString('base64'), {path: '/'});
            client.close();
          } else if (!invoice.status) {
            setPaidStatus('expired');
            createdPrs[createdPrs.length - 1].status = 'expired';
            setCreatedPrs(createdPrs);
            localStorage.setItem("prs", Buffer.from(JSON.stringify(createdPrs), 'utf8').toString('base64'));
          }
          // console.log(invoice);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, paymentUrl);

  const buildQueryParamString = () => {
    const paramObj = {
      merchant_name: jwtMeta.aud,
      invoice: name,
      order_key: name,
      merchant_addr: jwtMeta.adr,
      amount: price,
      return_json:true
    };
    if (jwtMeta.ttl) {
      paramObj.ttl = jwtMeta.ttl;
    }
    const queryString = new URLSearchParams(paramObj).toString();
    return queryString;
  }

  const ResultMessage = () => {
    if (requestError) {
      return (
        <span>{requestError}</span>
      );
    } else {
      return (
        <>
          <span>Payment request created successfully / 
            Invoice URL has been copied to clipboard:
          </span>
          <span>Invoice URL: {paymentUrl}</span>
          <span>Invoice ID: {name}</span>
          <span>Amount: {price}</span>
        </>
      );
    }
  }

  const ResultQr = () => {
    return (
        <QRCodeSVG 
          value={paymentUrl} 
          size={200}
        />
    )
  }

  const updatePrData = async () => {
    const prResults = createdPrs.map(prObj => {
      if (prObj.status !== 'open') {
        return (async () => { return {}; })();
      }
      const res = fetch(prObj.url, {
        headers: {
          'Accept': `application/payment-request`
        },
        method: "GET",
      }).then(res => res.json());
      return res;
    });
    const prResArr = await Promise.all(prResults);
    // console.log('prResArr', prResArr);
    const today = new Date(Date.now()).toLocaleDateString();
    const updatedPrs = JSON.parse(JSON.stringify(createdPrs));
    for (let i = updatedPrs.length - 1; i >= 0; i--) {
      const prDate = new Date(updatedPrs[i].ts * 1000).toLocaleDateString();
      if (prDate !== today) {
        updatedPrs.splice(i, 1);
        continue;
      }
      if (updatedPrs[i].status === 'open') {
        updatedPrs[i].status = prResArr[i].status ? prResArr[i].status : 'expired';
        if (updatedPrs[i].status === 'paid')
          updatedPrs[i].txid = prResArr[i].txHash;
      }
    }
    console.log('updatedPrs', updatedPrs);
    setCreatedPrs(updatedPrs);
    localStorage.setItem("prs", Buffer.from(JSON.stringify(updatedPrs), 'utf8').toString('base64'));
    // setCookie('prs', Buffer.from(JSON.stringify(createdPrs), 'utf8').toString('base64'), {path: '/'});
  }

  let handleSubmit = async (e) => {
    e.preventDefault();
    setPaidStatus('open');
    const queryParameters = new URLSearchParams(window.location.search)
    const env = queryParameters.get("e")
    console.log('env', env)
    try {
      const res = await fetch(`https://${env ? 'dev-api.' : ''}bux.digital/v2/pay?${buildQueryParamString()}`, {
        headers: {
          'Authorization': `Bearer: ${validJwt}`
        },
        method: "GET",
      });
      const resJson = await res.json();
      if (res.status === 200) {
        // setName("");
        // setPrice("");
        // copy text to clipboard
        console.log('resJson', resJson);
        navigator.clipboard.writeText(resJson.paymentUrl);
        setPaymentUrl(resJson.paymentUrl);
        setHasResults(true);
        // Store created invoice url in cookies
        createdPrs.push({
          url:resJson.paymentUrl, 
          status:'open',
          invoice: name,
          amount: price,
          ts: Math.ceil(Date.now() / 1000)
        });
        console.log('createdPrs', createdPrs);
        setCreatedPrs(createdPrs);
        localStorage.setItem("prs", Buffer.from(JSON.stringify(createdPrs), 'utf8').toString('base64'));
        // setCookie('prs', Buffer.from(JSON.stringify(createdPrs), 'utf8').toString('base64'), {path: '/'});
      } else {
        setRequestError(`Error: ${JSON.stringify(res.json)}`);
      }
    } catch (err) {
      setRequestError(err.message);
      console.log(err);
    }
  };

  return (
    <div className="App">
      <div className="formContainer">
        <div className="logobar">
          <img src={ecashSmall} alt="" width="70px"></img>
          <img src={buxIcon} alt="" width="20px" style={{"marginLeft": "8px"}}></img>
        </div>
      {(!validJwt || !jwtMeta) && (
        <div className="qrdisplay">
          <QrReader
            // facingMode={selected}
            // facingMode={"rear"}
            // delay={1000}
            // onError={handleError}
            // onScan={handleScan}
            // chooseDeviceId={()=>selected}
            onResult={handleScan}
            constraints={{ facingMode: 'environment' }}
            style={{ width: "300px" }}
          />
          <div className="message">
            <p>{"Scan QR code of valid JSON Web Token (JWT) or paste into box below."}</p>
            <input
              type="text"
              onChange={e => handleScan({text: e.target.value})}
              placeholder="Paste JWT here"
            />
            <p>{"For inquiries, email support@bux.digital"}</p>
          </div>
        </div>
      )}
      {(validJwt && jwtMeta && !hasResults) && (
          <form 
            className="centered"
            onSubmit={handleSubmit}
          >
            <IconContext.Provider value={{ size: "3em" }}>
              <div className="buttonbox">
                <div
                  onClick={() => {
                    if (!showSettings) {
                      updatePrData();
                    }
                    setShowSettings(!showSettings);
                  }}
                >
                  {showSettings ? (
                    <HiOutlineMenu />
                  ) : (
                    <BsGear />
                  )}
                </div>
              </div>
            </IconContext.Provider>
            <div className="message">
              {!showSettings && (
                <>
                  <span>{jwtMeta.aud}</span>
                  <span>
                    <a 
                      href={`https://explorer.e.cash/address/${jwtMeta.adr}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {`${jwtMeta.adr.replace('etoken:', '').slice(0, 5)}...${jwtMeta.adr.slice(-5)}`}
                    </a>
                  </span>
                </>
              )}
              {showSettings && (
                <>
                  <div className="invoice">
                    <span className="left"><u>INVOICE #</u></span>
                    <span className="center"><u>AMOUNT</u></span>
                    <span className="right"><u>STATUS</u></span>
                  </div>
                  {createdPrs.slice().reverse().map((pr, i) => {
                      return (
                        <div className="invoice" key={i}>
                          <span 
                            className="left"
                            onClick={() => {navigator.clipboard.writeText(pr.url)}}
                          >{pr.invoice}</span>
                          <span className="center">{pr.amount}</span>
                          {pr.status === 'paid' ? 
                            (
                              <span className="right">
                                <a 
                                  href={`https://explorer.e.cash/tx/${pr.txid}`}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >{pr.status}</a>
                              </span>
                            ) : (
                              <span className="right">{pr.status}</span>
                            )
                          }
                        </div>
                      );
                    }
                  )}
                </>
              )}
            </div>
            {!showSettings && (
              <>
              <input
                type="text"
                value={name}
                placeholder="Order ID"
                onChange={(e) => setName(e.target.value)}
              />
              <div className="errorDiv"><span>{nameError}</span></div>
              <input
                type="text"
                value={price}
                placeholder="Order Price (in USD)"
                onChange={(e) => handlePriceChange(e)}
              />
              <div className="errorDiv"><span>{priceError}</span></div>

              {(price && priceError === "" && name !== "") && (
                <button 
                  className="paybutton"
                  type="submit"
                >Generate Invoice</button>
              )}
              </>
            )}
          </form>
      )}
      {hasResults && (
        <div className="centered">
          <IconContext.Provider value={{ size: "3em" }}>
            <div className="buttonbox">
              {(paidStatus === 'open' && !requestError) && (
                <div
                  onClick={() => {
                    setShowQR(!showQr);
                  }}
                >
                  {showQr ? (
                    <BiAlignLeft />
                  ) : (
                    <BiQr />
                  )}
                </div>
              )}
            </div>
          </IconContext.Provider>
          <div className="message">
            <p>
            {paidStatus !== 'open' ? (
              <IconContext.Provider value={{ size: "10em" }}>
                {(paidStatus === 'paid' ?
                (
                  <>
                    <FcPaid />
                    <span>Invoice Paid!</span>
                  </>
                ) : (
                  <>
                    <FcExpired />
                    <span>Invoice Expired</span>
                  </>
                )
                )}
              </IconContext.Provider>
            ) : (showQr && !requestError ? 
              (
                <ResultQr />
              ) : (
                <ResultMessage />
              )
            )}
            </p>
          </div>
          <button 
            className="paybutton"
            onClick={() => handleGoBack()}>Go Back</button>
        </div>
      )}
      </div>
    </div>
  );
}

export default App;
